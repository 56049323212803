<template>
  <v-container v-if="category && product">
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="product-page__breadcrumbs">
          <span>{{ category.name }} / <span class="product-page__breadcrumbs-product-title">{{ product.name }}</span></span>
        </div>
      </v-col>
      <v-col cols="12" class="py-2">
        <h1 class="product-page__title d-block d-sm-none">{{ product.name }}</h1>
      </v-col>
      <v-col xl="5" lg="6" md="6" cols="12">
        <div class="product-page__slider">
          <div class="product-page__slider-images d-sm-block d-none" v-if="product.productImages && product.productImages.length">
            <v-img class="product-page__slider-images__image"
                   :src="imageUrl + product.preview"
                   @click="activeImage = product.preview" contain></v-img>
            <v-img class="product-page__slider-images__image"
                   v-for="image of product.productImages" :key="image.name"
                   :src="imageUrl + image.filename"
                   @click="activeImage = image.filename" contain></v-img>
          </div>
          <div class="product-page__slider-image__active" :class="{'product-page__slider-image__active-without__slider': !(product.productImages && product.productImages.length)}">
            <v-img :src="activeImage ? imageUrl + activeImage : imageUrl + product.preview" contain></v-img>
          </div>
          <div class="product-page__slider-images__horizontal d-flex d-sm-none" v-if="product.productImages && product.productImages.length">
            <v-img class="product-page__slider-images__image"
                   :src="imageUrl + product.preview"
                   @click="activeImage = product.preview" contain></v-img>
            <v-img class="product-page__slider-images__image"
                   v-for="image of product.productImages" :key="image.name"
                   :src="imageUrl + image.filename"
                   @click="activeImage = image.filename" contain></v-img>
          </div>
        </div>
        <div class="product-page__actions">
          <v-text-field solo hide-details class="product-page__actions-input" v-model="count" v-mask="'####'"></v-text-field>
          <v-btn class="button py-6 px-4" color="#018ABE" dark @click="addToCart()">Добавить в корзину
            <v-icon right>mdi-cart</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col xl="7" lg="6" md="6" cols="12">
        <h1 class="product-page__title d-sm-block d-none">{{ product.name }}</h1>
        <v-divider class="product-page__divider d-sm-block d-none"></v-divider>
        <h3 class="d-sm-none d-block">Описание</h3>
        <p class="product-page__desc" v-html="product.desc"></p>
        <v-divider class="product-page__divider d-sm-block d-none"></v-divider>
      </v-col>

      <v-col cols="12">
        <h3>Другие товары из "{{ category.name }}"</h3>
      </v-col>
      <v-col cols="6" sm="4" md="3" xl="2" v-for="categoryProduct of category.products"
             :key="'categoryProduct' + categoryProduct.id">
        <CustomCard :product="categoryProduct" :category="{id: category.id, name: category.name}"></CustomCard>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import CategoryService from "@/services/category.service";
import ProductService from "@/services/product.service";

import CustomCard from "@/components/oftenUsed/CustomCard"

export default {
  name: "ProductPageView",
  components: {CustomCard},
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          this.getProduct()
        }
    )
  },
  mounted() {
    this.getCategory()
    this.getProduct()
  },

  data() {
    return {
      category: null,
      product: null,

      activeImage: null,
      count: 1
    }
  },

  computed: {
    imageUrl() {
      return process.env.VUE_APP_API_URL + '/api/image/getProductImage/'
    },
    categoryId() {
      return this.$route.params.category.split('.').slice(-1)
    },
    productId() {
      return this.$route.params.productName.split('.').slice(-1)
    },
  },
  methods: {
    getCategory() {
      CategoryService.getCategoryById(this.categoryId).then(response => {
        this.category = response.data
      })
    },
    getProduct() {
      ProductService.getProductById(this.productId).then(response => {
        this.product = response.data
      })
    },
    addToCart() {
      let product = this.product
      product.category = this.category
      this.$store.dispatch('cart/addToCart', {product, count: this.count})
      this.count = 1
    }
  },
  watch: {
    'product.name'() {
      document.title = this.product.name + ' | Atomy'
    }
  }
}
</script>

<style lang="sass">
.product-page
  &__breadcrumbs
    font-family: "Roboto Light"
    font-size: 18px
    &-product-title
      color: #FFA000
  &__slider
    display: flex
    justify-content: space-between
    position: relative
    max-height: calc(160px * 3 + 12px * 2)

    &-images
      height: auto
      overflow-y: auto
      width: 22%

      &__image
        height: 160px
        margin-bottom: 12px
        border-radius: 8px

        &:last-child
          margin-bottom: 0

    &-image__active
      position: relative
      width: calc(78% - 12px)
      margin-left: 12px
      &-without__slider
        width: 100% !important

      .v-image
        height: 100%
        width: auto
        object-fit: fill !important
        border-radius: 8px

  &__actions
    display: flex
    justify-content: end
    margin-top: 24px

    &-input
      width: 100px
      flex: 0 1 auto

    .button
      margin-left: 8px
      font-size: 16px

  &__title
    font-family: "Roboto Regular"
    font-size: 28px

  &__desc
    font-family: "Roboto Light"
    font-size: 18px
    margin: 8px 0 !important

  &__divider
    border-color: #018ABE !important

@media (max-width: 1903.98px)
  .product-page
    &__slider
      max-height: calc(130px * 3 + 12px * 2)
      &-images
        &__image
          height: 130px
          margin-bottom: 4px

@media (max-width: 1263.99px)
  .product-page
    &__slider
      max-height: calc(100px * 3 + 12px * 2)
      &-images
        &__image
          height: 100px

    &__title
      font-size: 26px

    &__desc
      font-size: 16px
      margin: 4px 0 !important

@media (max-width: 959.98px)
  .product-page
    &__breadcrumbs
      font-size: 16px
    &__slider
      &-images
        width: 150px
      &-image__active
        width: calc(100% - 150px)
        margin-left: 8px
        &-without__slider
          width: 100% !important
    &__title
      font-size: 24px
    &__desc
      font-size: 16px

@media (max-width: 599.98px)
  .product-page
    &__breadcrumbs
      font-size: 14px
    &__slider
      display: flex
      flex-direction: column
      justify-content: space-between
      position: relative
      max-height: unset

      &-images__horizontal
        margin-top: 12px
        display: flex !important
        flex-wrap: nowrap
        height: auto
        overflow-x: auto
        width: 100%

      &-images
        &__image
          height: auto
          width: 100px
          margin-bottom: 8px !important
          margin-right: 4px !important
          border-radius: 8px
          &:last-child
            margin-right: 0 !important
      &-image__active
        width: auto
        margin-left: 0
        .v-image
          height: 100%
          width: auto
          object-fit: fill !important
          border-radius: 8px

    &__actions
      display: flex
      justify-content: center
      margin-top: 12px

      &-input
        width: 100px
        flex: 0 1 auto

      .button
        margin-left: 8px
        font-size: 12px

    &__title
      font-size: 18px

    &__desc
      font-size: 14px
</style>
